<template>
  <div class="main">
    <div class="grayBc" v-if="!isLoading">
      <!-- 白底盒子 -->
      <div class="form_Box">
        <div class="login-type-card">
          <a v-if="!checkLogin" class="login-type-sel">绑定手机号</a>
          <a v-if="checkLogin" class="login-type-sel">请选择要登录的食堂</a>
        </div>
        <!-- 绑定表单 -->
        <a-form-model v-if="!checkLogin" :model="loginData" @submit="handleSubmit" @submit.native.prevent>
          <a-form-model-item style="margin-top:40px;">
            <a-input size="large" v-model="loginData.username" placeholder="请输入手机号">
              <a-icon slot="prefix" type="user" style="color: rgba(0, 0, 0, 0.25)" />
            </a-input>
          </a-form-model-item>
          <span class="err-tips" v-if="errAccount">{{ errAccountText }}</span>

          <a-form-model-item style="margin-top:30px;">
            <div style="display:flex;justify-content:start;">
              <a-input size="large" v-model="loginData.password" type="text" placeholder="请输入验证码">
                <a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
              </a-input>
              <a-input size="large" style="width: 140px" v-model="btnText == null ? countNum + 's后重新发送' : btnText"
                type="button" class="sendCaptchaBtn" @click="getLoginCode" :disabled="countFlag"></a-input>
            </div>
          </a-form-model-item>
          <span class="err-tips2" v-if="errCode">{{ errCodeText }}</span>

          <a-form-model-item style="margin-top:70px;">
            <a-button size="large" block type="primary" html-type="submit" :disabled="loginData.user === '' ||
              loginData.password === ''
              ">确 认</a-button>
          </a-form-model-item>
        </a-form-model>
        <!-- 选择登录账号 -->
        <a-form-model v-else-if="checkLogin" :model="loginData" @submit="handleSubmit" @submit.native.prevent>
          <div style="overflow-y: scroll;height: 230px;scrollbar-width: none;padding: 10px 20px;">
            <a-radio-group @change="changeLogin">
              <div v-if="hasAdminArr">本手机号下的
                <span style="font-weight: 900;">超级管理员</span>
              </div>
              <a-radio v-if="item.is_admin == 1" style="display:block;height:60px;lineHeight:30px;"
                v-for="item in userlist" :value="item.inst_id" :key="item.id" name="inst">
                <span class="">{{ item.title }}</span>
                <span class="radio-item-info">{{ item.username }}</span>
              </a-radio>
              <div v-if="hasUserArr">本手机号下的
                <span style="font-weight: 900;">子管理员</span>
              </div>
              <a-radio v-if="item.is_admin == 0" style="display:block;height:60px;lineHeight:30px;"
                v-for="item in userlist" :value="item.inst_id" :key="item.id" name="inst">
                <span class="">{{ item.title }}</span>
                <span class="radio-item-info">{{ item.username }}</span>
              </a-radio>
            </a-radio-group>
          </div>
          <div style="text-align: center;margin-top: 10px;">
            <a class="ant-btn ant-btn-lg ant-btn-primary" style="width: 48%;" @click="handleSubmit">确定</a>
            <a class="ant-btn ant-btn-lg ant-btn-info" style="width: 48%;" @click="cancelCheck">取消</a>
          </div>
        </a-form-model>
        <!-- 选择登录账号结束 -->
      </div>

    </div>
      <div v-if="isLoading" style="width: 200px;">
        <a-spin tip="加载中..." size="large">
          <div class="spin-content">
          </div>
        </a-spin>
      </div>
    <a-modal v-model="visible" title="操作提示" okText="继续" cancelText="取消" @ok="handleOk" destroyOnClose>
      {{ visibleMsg }}</a-modal>
  </div>
</template>

<script>
import {
  webAppBind,
  scanCodeLogin, getQrCode,
  easyLogin,
  getCaptchaImage,
  getWebCode,
  getLoginCode,
  resetInstPass,
  loginStatus,
  isRemind
} from "@/request/api";
import util from "@/utils/util";

export default {
  data() {
    return {
      loginData: {
        state: "",
        code: "",
        username: "",
        password: "",
        inst_id: '',
        type: 'bindWebApp',
        overrideBinding: false
      },
      verificationSrc: "",
      isLoading: true,
      changePwdData: {
        tel: "",
        password: "",
        code: "",
      },
      visible: false,
      visibleMsg: "",
      btnText: "发送验证码",
      countNum: 60,
      // 用于倒计时标记，true-正在倒计时
      countFlag: false,
      // 定时器
      intervalBtn: {},
      checkLogin: false,
      errAccount: false,
      errAccountText: '',
      errPsw: false,
      errPswText: '',
      errCode: false,
      errCodeText: '',
      userlist: [],
      inst_id: '',
      hasAdminArr: false,
      hasUserArr: false,
      qrCodeUrl: '',
      state: '',
    };
  },
  computed: {},
  components: {},
  created() {
    this.changeChrome();

    this.loginData.state = this.$route.query.state;
    this.loginData.code = this.$route.query.code;
    this.scanCodeLoginFunction();
  },
  mounted() {
  },
  methods: {
    //继续
    handleOk() {
      //清空错误提示
      this.visible = false;
      this.loginData.overrideBinding = true;
      this.bindWebAppFunction();
    },
    goLogin() {
      //清空错误提示
      this.errAccount = false;
      this.errPsw = false;
      this.errCode = false;
      // this.isLoading = false;
      this.changePwdData.tel = "";
      this.changePwdData.password = "";
      this.changePwdData.code = "";
    },
    //发送短信验证码
    sendCaptcha() {
      //清空错误提示
      this.errAccount = false;
      this.errPsw = false;
      this.errCode = false;
      getWebCode({ tel: this.changePwdData.tel }).then((res) => { }).catch((err) => {
        if (err.status == 201) {
          this.$message.success(err.data.msg);
          this.countDown();
        } else {
          //this.$message.error(err.data.msg);
          if (err.data.error_code == 1) {
            //账号类
            this.errAccountText = err.data.msg;
            this.errAccount = true;
          } else if (err.data.error_code == 2) {
            //密码类
            this.errPswText = err.data.msg;
            this.errPsw = true;
          } else {
            //验证码
            this.errCodeText = err.data.msg;
            this.errCode = true;
          }
        }
      });
    },
    //倒计时
    countDown() {
      this.btnText = null;
      this.countFlag = !this.countFlag;
      // 设置倒计时
      this.intervalBtn = setInterval(() => {
        if (this.countNum <= 0) {
          // 重置btn提示信息
          this.btnText = "发送验证码";
          // 清除定时器
          clearInterval(this.intervalBtn);
          // 更改btn状态
          this.countFlag = !this.countFlag;
          // 重置倒计时状态
          this.countNum = 60;
        }
        // 倒计时
        this.countNum--;
      }, 1000);
    },
    // getQrCodeFunction() {
    //   getQrCode().then((res) => {
    //     // this.qrCodeUrl = res.data.data.qr_code_url;
    //     // console.log(res.data);
    //     this.state = res.data.data.state;
    //   });
    // },
    scanCodeLoginFunction() {
      // if (this.checkLogin) {
      //   this.loginData.inst_id = this.inst_id;
      // }
      scanCodeLogin(this.loginData)
        .then(async (res) => {
          console.log('res', res);
          if (typeof (res.data.error_code) != 'undefined') {
            if (res.data.error_code == 9) {
              this.$message.error(res.data.message);
              this.toLogin();
            } else if (res.data.error_code == 10) {
              this.isLoading = false;
            }
          } else {

            this.$message.success("登录成功!");
            this.isLoading = true;
            this.$store.commit("ADD_COUNT", res.data.access_token);
            this.$store.commit("ADD_VERSION", res.data.version);
            await this.$store.dispatch("GET_INST_INFO");
            this.$store.dispatch("GET_ME").then((res) => {
              this.$store.dispatch("GET_ALL_AUTH").then(async (res) => {
                this.$router.push("/");
                // 请求接口判断是否需要弹窗过期提醒
                let { data } = await isRemind();
                if (data.length != 0) {
                  this.$info({
                    content: data.msg,
                  });
                }
              });
            });
          }
        })
        .catch((err) => {
          // this.$message.error(err.data.error_code);
          console.log(err)
          if (typeof (err.data.error_code) != 'undefined') {
            if (err.data.error_code == -1) {
              //存在多个账号
              this.isLoading = false;
              this.checkLogin = true;
              this.userlist = err.data.data;
              for (let index = 0; index < this.userlist.length; index++) {
                if (this.userlist[index].is_admin == 1) {
                  this.hasAdminArr = true;
                } else {
                  this.hasUserArr = true;
                }
              }
            } else if (err.data.error_code == 1) {
              //账号类错误
              this.errAccountText = err.data.msg;
              this.errAccount = true;
            } else if (err.data.error_code == 3) {
              //验证码
              this.errCodeText = err.data.msg;
              this.errCode = true;
            } else {
              //密码类错误
              this.errPswText = err.data.msg;
              this.errPsw = true;
            }
          }
          // this.errCodeText = err.data.msg;
          // this.errCode = true;
        });
    },
    bindWebAppFunction() {
      if (this.checkLogin) {
        this.loginData.inst_id = this.inst_id;
      }
      webAppBind(this.loginData)
        .then(async (res) => {

          if (typeof (res.data.error_code) != 'undefined') {
            if (res.data.error_code == 11) {
              //换绑提示
              this.visible = true;
              this.visibleMsg = res.data.message;
            }
          } else {
            console.log('res', res);
            if (this.loginData.inst_id == '') {
              this.$message.success("绑定成功!");
            } else {
              this.$message.success("登录成功!");
            }
            this.isLoading = true;
            this.$store.commit("ADD_COUNT", res.data.access_token);
            this.$store.commit("ADD_VERSION", res.data.version);
            await this.$store.dispatch("GET_INST_INFO");
            this.$store.dispatch("GET_ME").then((res) => {
              this.$store.dispatch("GET_ALL_AUTH").then(async (res) => {
                this.$router.push("/");
                // 请求接口判断是否需要弹窗过期提醒
                let { data } = await isRemind();
                if (data.length != 0) {
                  this.$info({
                    content: data.msg,
                  });
                }
              });
            });
          }
        })
        .catch((err) => {
          // this.$message.error(err.data.error_code);
          console.log(err)
          if (typeof (err.data.error_code) != 'undefined') {
            if (err.data.error_code == -1) {
              //存在多个账号
              this.isLoading = false;
              this.checkLogin = true;
              this.userlist = err.data.data;
              for (let index = 0; index < this.userlist.length; index++) {
                if (this.userlist[index].is_admin == 1) {
                  this.hasAdminArr = true;
                } else {
                  this.hasUserArr = true;
                }
              }
            } else if (err.data.error_code == 9) {
              //异常
              this.$message.error(res.data.message);
              this.toLogin();
            } else if (err.data.error_code == 1) {
              //手机号错误
              this.errAccountText = err.data.msg;
              this.errAccount = true;
            } else if (err.data.error_code == 3) {
              //验证码
              this.errCodeText = err.data.msg;
              this.errCode = true;
            }
          }
          // // this.errCodeText = err.data.msg;
          // // this.errCode = true;
        });
    },
    //登录
    handleSubmit() {
      //清空错误提示
      this.errAccount = false;
      this.errPsw = false;
      this.errCode = false;

      // let param = {
      //   username: this.loginData.username,
      //   password: this.loginData.password,
      // };
      this.loginData.overrideBinding = false;
      this.bindWebAppFunction();
      return;
    },
    // 引导使用谷歌浏览器
    changeChrome() {
      // console.log(util.myBrowser());
      if (util.myBrowser() != "Chrome") {
        this.$message.info("为减少不必要的BUG，请使用谷歌浏览器登录系统");
      }
    },
    getLoginCode() {
      //清除错误提示
      this.errAccount = false;
      this.errPsw = false;
      this.errCode = false;

      if (!this.loginData.username) {
        this.errAccountText = "手机号码不能为空";
        this.errAccount = true;
        return;
      }

      getLoginCode({ tel: this.loginData.username }).then((res) => { }).catch((err) => {
        if (err.status == 201) {
          this.$message.success(err.data.msg);
          this.countDown();
        } else {
          //this.$message.error(err.data.msg);
          if (err.data.error_code == 1) {
            //账号类
            this.errAccountText = err.data.msg;
            this.errAccount = true;
          } else if (err.data.error_code == 2) {
            //密码类
            this.errPswText = err.data.msg;
            this.errPsw = true;
          } else {
            //验证码
            this.errCodeText = err.data.msg;
            this.errCode = true;
          }
          return;
        }
      });
    },
    changeLogin(v) {
      console.log(v.target.value);
      this.inst_id = v.target.value;
    },
    cancelCheck() {
      this.toLogin();
    },
    toLogin() {
      // TODO::1秒后跳转到登录页面
      setTimeout(() => {
        this.$router.push('/login');
      }, 1000);
    }
  },
};
</script>
<style lang='less' scoped>
@import '~@/style/style.less';

.main {
  background-image: url("https://styf.oss-cn-shenzhen.aliyuncs.com/business/productions/202107/20250212094429.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

  .grayBc {
    position: relative;
    padding: 15px 20px;
    // background: linear-gradient(
    //   to bottom,
    //   rgba(0, 0, 0, 0.4) 10%,
    //   rgba(0, 0, 0, 0.3) 100%
    // );
    background-color: #fff;
    width: 500px;
    height: 400px;

    // left:12%;
    .blue {
      // color: @color-main;
      color: #000;
      font-weight: 100;
      margin-bottom: 0;
      font-size: 20px;
    }

    .light_blue {
      // color: #cee1e9;
      color: #aaaaaa;
      margin-top: 10px;
      font-size: 14px;
    }

    .forgetPwdBtn {
      border: 1px solid #cee1e9;
      color: #cee1e9;
      width: 160px;
      height: 35px;
      text-align: center;
      line-height: 30px;
    }

    .forgetPwdBtn:hover {
      cursor: pointer;
      color: white;
      border: 1px solid white;
    }

    .qrcodeBox {
      border-right: 1px solid #cee1e9;
      color: #cee1e9;
      width: 320px;
      height: 85%;
      text-align: center;
      padding: 20px;
      margin-top: 5%;

    }

    .form_Box {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      // background-color: #fafafa;
      background-color: #FFFFFF;
      padding: 20px;

      .sign_title {
        color: @color-main;
        font-size: 23px;
        font-weight: 100;
        margin: 20px 20px 40px;
      }

      .ant-form {
        margin-top: 0px;
      }

      .ant-form-item {
        margin: 0 20px;
      }

      .sendCaptchaBtn {

        color: #fff;
        background-color: #FF833D;
      }

      .verification_pic {
        // z-index: 999!important;
        width: 100px;
        height: 39px;
        position: absolute;
        top: -12px;
        right: 1px;
      }

      .changePic {
        float: right;
        color: #12a6fe;
        margin: 0;
      }

      .login-type-card {
        margin: 20px 20px 10px;
      }

      .login-type-card a {
        color: #000;
        font-weight: 100;
        font-size: 23px;
        margin-right: 20px;
      }

      .login-type-sel {
        color: #FF833D !important;
        border-bottom: 2px solid #FF833D;
      }

      .err-tips {
        color: red;
        padding-left: 22px;
        font-size: 12px;
        position: absolute;
        top: 154px;
        z-index: 999;
      }

      .err-tips2 {
        color: red;
        padding-left: 22px;
        font-size: 12px;
        position: absolute;
        top: 225px;
        z-index: 999;
      }

      .err-tips3 {
        color: red;
        padding-left: 22px;
        font-size: 12px;
        position: absolute;
        top: 296px;
        z-index: 999;
      }

      .err-tips4 {
        color: red;
        padding-left: 22px;
        font-size: 12px;
        position: absolute;
        top: 146px;
        z-index: 999;
      }

      .err-tips5 {
        color: red;
        padding-left: 22px;
        font-size: 12px;
        position: absolute;
        top: 217px;
        z-index: 999;
      }
    }

    .radio-item-info {
      display: block;
      padding-left: 25px;
    }

    .ant-btn-info {
      color: #FF833D;
      background-color: #fff;
      border-color: #FF833D;
      //text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
      //box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
      margin-left: 10px;
    }
  }

}
</style>
