<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
import util from "@/utils/util";
import {
  baseUrl
} from '@/request/http'
moment.locale("zh-cn");
export default {
  data () {
    return {
      zh_CN,
    };
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  methods: {
    async beforeunloadHandler (e) {
      let param = await util.createModuleStayTime();
      if(param.last_id){
        window.navigator.sendBeacon(`${baseUrl}/home/external/recordUsedFunction?last_id=${param.last_id}&inst_id=${param.inst_id}&user_id=${param.user_id}`);
        // window.navigator.sendBeacon(`https://business.51baocan.com/home/external/recordUsedFunction?last_id=${param.last_id}`);
      }
    },
  }
};
</script>
<style lang="less" scoped>
#app {
  height: 100%;
  //修改表头文字、背景颜色
  ::v-deep .ant-table-thead > tr > th {
    border-bottom: 0 !important;
    background: #f0f2f5 !important;
  }

  //修改列表文字、背景颜色
  ::v-deep .ant-table-tbody > tr > td {
    border-bottom: 10px solid #f0f2f5;
    background: white !important;
  }
}
.right {
  float: right;
}
.custom-confirm-content {
  color: #ff0000 !important; /* 设置为你想要的颜色 */
}
</style>
