<template>
  <a-layout id="components-layout-demo-side" style="min-height: 100vh">
    <!-- 头部 -->
    <a-layout-header class="headerStyle">
      <div style="display: flex;flex-direction: row;justify-content: space-between;padding: 0 16px;">
          <div class="img">
            <div class="icon">
              <a-icon @click="toggleCollapsed" :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                :style="{ fontSize: '36px', color: '#ffffff' }" />
            </div>
          <img v-if="!agent_inst" class="header-img" src="../assets/logo.png" alt />
        </div>

        <Header-right @showKeFu="showKeFuFn" @showChangeInst="showChangeInstFn" :agent_inst="agent_inst"></Header-right>
      </div>
      
      <a href="/ServiceModules">
        <img style="height: auto;width: 100%;" src="../assets/web_banner.png" />
      </a>
      
      

    </a-layout-header>
    <!-- 左侧导航栏 -->
    <a-layout >
      <a-layout-sider theme="light" v-model="collapsed" style="overflow: auto; height: 92vh" v-if="showCollapsed">
        <!-- 管理员 -->
        <a-menu mode="inline" :selectedKeys="selectedKeys" :openKeys="openKeys" @openChange="openChangeFn"
          @click="select">
          <template v-for="item in menuArr">
            <!-- 没有二级路由 -->
            <a-menu-item v-if="!item.children" :key="item.name">
              <a-icon :type="item.meta.icon" :style="{ fontSize: '22px', margin: 'auto 0' }" />
              <span style="margin-left: 12px">{{ item.name }}</span>
              <router-link :to="item.path" />
            </a-menu-item>
            <!-- 有二级路由 -->
            <a-sub-menu v-if="item.children && item.meta.show" :key="item.name">
              <span slot="title">
                <a-icon :type="item.meta.icon" :style="{ fontSize: '22px' }" />
                <span>{{ item.name }}</span>
              </span>
              <template v-for="subItem in item.children">
                <a-menu-item :key="subItem.path" v-if="subItem.meta.requireAuth">
                  <router-link :to="subItem.path">{{ subItem.name }}</router-link>
                </a-menu-item>
              </template>
            </a-sub-menu>
          </template>
        </a-menu>
      </a-layout-sider>

      <!-- 右侧区域 -->
      <!-- <a-layout> -->
      <!-- 内容 -->
      <a-layout-content style="
            margin-left: 16px;
            backgroundcolor: #f0f2f5;
            overflow: auto;
            height: 92vh;
          " v-if="showContent == 1">
        <div :style="{ height: '100%' }">
          <router-view></router-view>
        </div>
      </a-layout-content>

      <!-- 内容 -->
      <a-layout-content style="margin-left: 16px;backgroundcolor: #f0f2f5;overflow: auto;height: 92vh;" v-if="showContent == 2">
        <div style="height: 100%;text-align: center;margin-top: 150px;">
          <img src="../assets/waring.png">
          <div class="ver-title">试用已到期</div>
          <div class="ver-tips">已经超过试用期限，请升级为付费版本后继续使用</div>
          <a class="ver-btn" href="javascript:;" @click="goVersion">升级为付费版本</a>
        </div>
      </a-layout-content>

      <!-- 底部 -->
      <!-- <a-layout-footer style="text-align: right">©2017-2019 食堂有饭</a-layout-footer> -->
      <!-- </a-layout> -->
    </a-layout>
    <a-modal v-model="showKefu" title="请扫描客服二维码" :footer="null" :width="250">
      <img style="width:200px;margin: 0 auto;" :src="kefuSrc">
    </a-modal>
    <a-modal v-model="showChangeInst" title="请选择要切换的食堂" :width="550" @ok="handleOk">
      <a-radio-group @change="changeUser">
        <div v-if="adminlist.length != 0">本手机号下的超级管理员</div>
        <a-radio style="display:block;height:60px;lineHeight:30px;" v-for="item in adminlist" :value="item.id"
          :key="item.id" name="inst">
          <span class="">{{ item.title }}</span>
          <span style="display: block;padding-left: 25px;">{{ item.username }}</span>
        </a-radio>
        <div v-if="userlist.length != 0">本手机号下的子管理员</div>
        <a-radio style="display:block;height:60px;lineHeight:30px;" v-for="item in userlist" :value="item.id"
          :key="item.id" name="inst">
          <span class="">{{ item.title }}</span>
          <span style="display: block;padding-left: 25px;">{{ item.username }}</span>
        </a-radio>
      </a-radio-group>
    </a-modal>
  </a-layout>
</template>

<script>
import { getMe, getAllUser, changeLogin, getCustomerService} from "@/request/api";
import util from "@/utils/util";
import HeaderRight from "@/components/common/HeaderRight.vue";

export default {
  data() {
    return {
      openKeys: [this.$route.meta.parent],
      openKeysCurrent: [],
      showKefu: false,
      showChangeInst: false,
      agent_inst: false,
      adminlist: [],
      userlist: [],
      showCollapsed:false,
      showContent:0,
      kefuSrc:'https://styf.oss-cn-shenzhen.aliyuncs.com/uploads/images/qrcode/lanjie.png'
    };
  },
  name: "Home",
  mounted() {
    this.agent_inst = JSON.parse(localStorage.getItem("agent_inst"));
  },
  components: {
    HeaderRight,
  },
  created() {
    // console.log('home.vue中左侧权限数组')
    // console.log(this.menuArr)
    this.getMeFn();
    this.$store.commit('UPDATE_SELECTEDKEYS', [this.$route.path])
    this.changeStaff();
  },
  computed: {
    collapsed() {
      return !this.$store.state.collapsed
    },
    menuArr() {
      // console.log(this.$store.state.useRouter)
      return this.$store.state.useRouter
    },
    selectedKeys() {
      return this.$store.state.selectedKeys
    }
  },
  watch: {
    collapsed(newValue, oldValue) {
      if (newValue) {
        this.openKeys = []
      }
    },
  },
  methods: {
    //获取个人信息
    async getMeFn() {
      let that = this;
      let { data } = await getMe({
        version: localStorage.getItem("version"),
      });
      that.global.userInfo = data;
      if( data.collapsed == 0  ){
          //过期
          //续费,正常显示,隐藏侧边栏
          if(that.$route.path == '/VersionInformation'){
            that.showContent = 1;
          } else {
            //显示续费页
            that.showContent = 2;
          }
      } else {
        //未过期,正常显示
        that.showCollapsed = true;
        that.showContent   = 1;
      }
      that.$forceUpdate();
    },
    toggleCollapsed() {
      this.$store.commit('CHANGE_COLLAPSED')
      if (!this.collapsed) {
        this.openKeys = this.openKeysCurrent
      }
    },
    logOut() {
      this.$confirm({
        title: "是否退出当前账户",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.$store.commit("REMOVE_COUNT");
        },
      });
    },
    openChangeFn(openKeys) {
      this.openKeys = openKeys;
    },
    select({ item, key, keyPath }) {
      // this.selectedKeys = [key];
      this.$store.commit('UPDATE_SELECTEDKEYS', [key])
      if (keyPath.length != 0) {
        this.openKeysCurrent = []
        if (keyPath.length == 1) {
          this.openKeysCurrent.push(keyPath[0])
        }
        if (keyPath.length == 2) {
          this.openKeysCurrent.push(keyPath[1])
        }
      }
    },
    showKeFuFn(e) {
      this.showKefu = e.showCode
    },
    //切换专属客服
    changeStaff(){
      // let staff_id = JSON.parse(localStorage.getItem('inst')).staff_id;
      // console.log(staff_id);
      // if(staff_id == '29'){
      //   this.kefuSrc = 'https://styf.oss-cn-shenzhen.aliyuncs.com/uploads/images/qrcode/xiaomai.png';
      // } else if(staff_id == '42'){
      //   this.kefuSrc = 'https://styf.oss-cn-shenzhen.aliyuncs.com/uploads/images/qrcode/dami.png';
      // } else if(staff_id == '37'){
      //   this.kefuSrc = 'https://styf.oss-cn-shenzhen.aliyuncs.com/uploads/images/qrcode/xiaomi.png';
      // } else {

      // }
      getCustomerService({})
        .then(async (res) => {
          console.log(res);
          this.kefuSrc = res.data.qrcode;
          localStorage.setItem('kefuSrc', res.data.qrcode);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showChangeInstFn(e) {
      let that_ = this;
      //获取用户食堂列表
      getAllUser().then((res) => {
        if (res.data.error_code == 1) {
          this.$message.error('当前无可切换食堂');
        } else {
          if(typeof(res.data.data.adminlist) != 'undefined'){
            that_.adminlist = res.data.data.adminlist;
          }
          if(typeof(res.data.data.userlist) != 'undefined'){
            that_.userlist  = res.data.data.userlist;
          }
          this.showChangeInst = e.showInst;
        }
      });

    },
    handleOk() {
      if (typeof (this.inst_id) == 'undefined' || this.inst_id < 1) {
        this.$message.error('请选择要切换的食堂');
        return;
      }

      let parame = { id: this.inst_id };
      changeLogin(parame)
        .then(async (res) => {
          this.$store.commit("ADD_COUNT", res.data.access_token);
          this.$store.commit("ADD_VERSION", res.data.version);
          await this.$store.dispatch("GET_INST_INFO");
          this.$store.dispatch("GET_ME").then((res) => {
            this.$store.dispatch("GET_ALL_AUTH").then(async (res) => {
              this.$router.push("/");
            });
          });
          this.$message.success("切换成功!");
          setTimeout(function () {
            // location.reload();
            location.href = '/Dashboard';
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });

      this.showChangeInst = false;
    },
    handleCancel() {
      this.showChangeInst = false;
    },
    changeUser(v) {
      this.inst_id = v.target.value;
    },
    goVersion(){
      location.href = '/VersionInformation';
    },
    goService(){
      location.href = '/ServiceModules';
    },
  },
};
</script>
<style lang="less">
@import '~@/style/style.less';
#components-layout-demo-side {
  .headerStyle {
    background-color: @color-main;
    height: calc(@height-base + 62px);
    position: relative;
    padding: 0;
    .img {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: @height-base;
      width: 200px;
      user-select: none;

      .icon {
        // margin: 10px 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: @height-sm;
      }

      .header-img {
        margin: 5px 20px;
        width: 100%;
        height: @height-sm;
      }

      .dropdown {
        height: @height-base;

        .content {
          font-size: 20px;
        }
      }
    }

    .linkKF {
      height: 100%;
      margin-right: 20px;
      font-size: 20px;
      float: left;
      color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    // .linkKF:hover{
    //   font-size: 22px;
    // }
  }

  .radio-item-info {
    display: block;
    padding-left: 25px;
  }
  .ver-title{
    font-size: 24px;
    font-weight: 600;
    margin-top: 10px;
  }
  .ver-tips{
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 25px;
  }
  .ver-btn{
    color: #fff;
    background-color: #FF833D;
    padding: 12px 24px;
    border-radius: 8px;
  }
}
</style>
